
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'add-assessor',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      dialogImageUrl: '',
      load: false,
      imageUrl: '',
      imageEntityLogoUrl: '',
      file_name: '',
      dialogVisible: false,
      alertText: false,
      courseInfoData: false,
      courseList: [],
      educational_details: [
        {
          institute_name: '',
          level_of_education: '',
          subject: '',
          passing_year: '',
        },
      ] as any,
      fromYear: [] as any,
      education: {
        institute_name: '',
        level_of_education: '',
        subject: '',
        passing_year: '',
      },
      professional_details: [
        {
          designation: '',
          organization: '',
          address: '',
          exp_from: '',
          exp_to: '',
        },
      ] as any,
      educationLabel: [
        'SSC',
        'DAKHIL',
        'HSC',
        'ALIM',
        'BSC/HONOURS',
        'Diploma',
        'FAZIL',
        'KAMIL',
        'MASTERS',
        'PHD',
      ],
      profession: {
        designation: '',
        organization: '',
        address: '',
        exp_from: '',
        exp_to: '',
      },
      createAssessor: false,
      employee: {
        employee_id: '',
        father_name: '',
        mother_name: '',
        name: '',
        dob: '',
        nid: '',
        bcn: '',
        passport: '',
        driving_license: '',
        mobile: '',
        email: '',
        gender: '',
        religion: '',
        is_trainer: '',
        is_assessor: '1',
        present_address: '',
        present_district: '',
        permanent_address: '',
        permanent_district: '',
        image_file_name: {},
      },
      user: {
        password: '',
      },
      certificate: {
        certificate_name: '',
        organization_name: '',
        year: '',
      },
      certificates: [
        {
          certificate_name: '',
          organization_name: '',
          year: '',
        },
      ] as any,
      bteb_registration_field: false,
      nsda_registration_field: false,
      entity_assessor: {
        is_bteb_registered: '',
        skill_level_bteb: '',
        occupation_bteb: '',
        pedagogy_level_bteb: '',
        pedagogy_part_achieved_bteb: '',
        bteb_registration_number: '',
        area_of_expertise: '',
        is_nsda_certified: '',
        skill_level_nsda: '',
        occupation_nsda: '',
        nsda_registration_number: '',
        pedagogy_level_nsda: '',
        pedagogy_part_achieved_nsda: '',
      },
      entityInstitute: {
        entity_info_id: '',
        institute_info_id: '',
        tranche_id: '',
        course_id: '',
      },
      entityRoles: [],
      url: '',

      entityInfos: [],
      institutes: [],
      empDistricts: [],
      tranche: [],
      nidData: {},
      loading: false,
      showTrainerInfo: false,
      showNidInfo: true,
    };
  },
  async created() {
    await this.getTranche();
    await this.getDistrictForEmp();
    await this.getEntityInfos();
    await this.getYear();
  },
  methods: {
    async courseInfo() {
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
        '?entity_id=' +
        this.entityInstitute.entity_info_id +
        '&tranche=' +
        this.entityInstitute.tranche_id +
        '&institute_info_id=' +
        this.entityInstitute.institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.courseList = response.data.data;
          this.courseInfoData = true;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getYear() {
      let currentYear = new Date().getFullYear();
      for (let i = 50; i > 0; i--) {
        this.fromYear.push(currentYear);
        //console.log(this.fromYear);
        currentYear--;
      }
    },
    verifySkip() {
      if (this.employee.dob) {
        this.showTrainerInfo = true;
        this.showNidInfo = false;
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Date of birth field is required.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },

    updatePhoto(e) {
      this.file_name = e.target.files[0];
      this.url = URL.createObjectURL(this.file_name);
    },
    bteb_registered(e) {
      if (e.target.checked) {
        this.entity_assessor.is_bteb_registered = '1';
      } else {
        this.entity_assessor.is_bteb_registered = '0';
      }
    },
    nsda_certified(e) {
      if (e.target.checked) {
        this.entity_assessor.is_nsda_certified = '1';
      } else {
        this.entity_assessor.is_nsda_certified = '0';
      }
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    handleChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      if (fileList.length > 0) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.employee = file;
      }
    },
    async formSubmit() {
      let formData = new FormData();

      console.log(this.file_name);
      // formData.append("file_name", this.file_name);
      formData.set('file_name', this.file_name);
      for (var key in this.employee) {
        formData.set(key, this.employee[key]);
      }
      for (var key in this.user) {
        formData.set(key, this.user[key]);
      }
      for (var key in this.entity_assessor) {
        formData.set(key, this.entity_assessor[key]);
      }
      for (var key in this.entityInstitute) {
        formData.set(key, this.entityInstitute[key]);
      }
      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_seip_role_id');
      formData.set('user_id', user_id.id);
      formData.set('role_id', role_id);
      formData.set('module_type', 'assessor');
      formData.set('certificates', JSON.stringify(this.certificates));
      formData.set(
        'educational_details',
        JSON.stringify(this.educational_details)
      );
      formData.set(
        'professional_details',
        JSON.stringify(this.professional_details)
      );
      //return(console.log(formData.get('file_name')));
      let data = {
        employee: this.employee,
        user: this.user,
        entity_assessor: this.entity_assessor,
        entityInstitute: this.entityInstitute,
      };
      console.log(data);

      // for ( var key in data ) {
      //   formData.append(key, data[key]);
      // }

      let route = this.createAssessor
        ? 'entity/assessor/assessor-save-trainer'
        : 'entity/assessor/save';
      this.loading = true;

      await ApiService.post(route, formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              (this.employee = {
                employee_id: '',
                father_name: '',
                mother_name: '',
                name: '',
                dob: '',
                nid: '',
                bcn: '',
                passport: '',
                driving_license: '',
                mobile: '',
                email: '',
                gender: '',
                religion: '',
                is_trainer: '',
                is_assessor: '1',
                present_address: '',
                present_district: '',
                permanent_address: '',
                permanent_district: '',
                image_file_name: {},
              }),
                (this.user = {
                  password: '',
                });
              (this.entity_assessor = {
                is_bteb_registered: '',
                skill_level_bteb: '',
                occupation_bteb: '',
                pedagogy_level_bteb: '',
                pedagogy_part_achieved_bteb: '',
                bteb_registration_number: '',
                area_of_expertise: '',
                is_nsda_certified: '',
                skill_level_nsda: '',
                occupation_nsda: '',
                nsda_registration_number: '',
                pedagogy_level_nsda: '',
                pedagogy_part_achieved_nsda: '',
              }),
                (this.entityInstitute = {
                  entity_info_id: '',
                  institute_info_id: '',
                  tranche_id: '',
                  course_id: '',
                });
              this.showTrainerInfo = false;
              this.createAssessor = false;
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Error!',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },

    async getDistrictForEmp() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityInfos() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    addcertificate() {
      this.certificates.push(JSON.parse(JSON.stringify(this.certificate)));
      console.log(this.certificates);
    },
    removecertificate() {
      this.certificates.length--;
    },
    addEducation() {
      this.educational_details.push(JSON.parse(JSON.stringify(this.education)));
    },
    removeEducation() {
      this.educational_details.length--;
    },
    addProfession() {
      this.professional_details.push(
        JSON.parse(JSON.stringify(this.profession))
      );
    },
    removeProfession() {
      this.professional_details.length--;
    },

    async trainingInstitute() {
      await ApiService.get(
        'institute/list?entity_id=' + this.entityInstitute.entity_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    fetchNID() {
      console.log('API IS CALLING');
    },
    checkBteb() {
      this.load = true;
      ApiService.get(
        'entity/assessor/checkBtebNumber?bteb_registration_number=' +
        this.entity_assessor.bteb_registration_number
      )
        .then((response) => {
          this.load = false;
          if (response.data.data) {
            Swal.fire({
              title: 'The Assessor already Exists',
              text: '',
              icon: 'warning',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            });
          } else {
            Swal.fire({
              title: 'The Assessor is New',
              text: '',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            });
          }
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },
    async nidverification(nidId) {
      if (this.employee.nid.length >= 10 && this.employee.dob) {
        this.load = true;
        await ApiService.get('configurations/employee/nid-verify?nid=' + nidId + '&dob=' + this.employee.dob)
          .then((response) => {
            this.load = false;
            if (
              response.data.status == 'success' &&
              response.data.data.employee
            ) {
              let role = response.data.data.employee;
              this.employee.employee_id = role.id;
              // for (let i = 0; i < role.length; i++) {
              //   let data = role[i];
              //   //console.log(data)
              //   this.role_title.push(data);
              // }
              // console.log(this.role_title[0].role_title);
              if (role.is_trainer && role.is_assessor) {
                Swal.fire({
                  title:
                    'This user already appointed as Assessor and also a Trainer',
                  text: '',
                  icon: 'warning',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                });
              } else if (role.is_trainer && !role.is_assessor) {
                Swal.fire({
                  title: 'This user already appointed as Trainer',
                  text: 'Are you want to create as an assessor?',
                  icon: 'warning',
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Create Assessor',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger',
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.createAssessor = true;
                  }
                });
              } else if (role.is_employee) {
                Swal.fire({
                  title: 'This user already appointed as an Employee',
                  text: 'Are you want to create as an assessor?',
                  icon: 'warning',
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Create Assessor',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger',
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.createAssessor = true;
                  }
                });
              }
              // console.log(this.role_title);
            } else if (
              response.data.status === 'success' &&
              !response.data.data.employee
            ) {
              this.showTrainerInfo = true;
              this.employee.name = response.data.data.nameEn;
              this.employee.mother_name = response.data.data.mother;
              this.employee.father_name = response.data.data.father;
              this.employee.gender = response.data.data.gender;
              this.employee.present_address = response.data.data.presentAddress.postOffice;
              this.employee.permanent_address = response.data.data.permanentAddress.postOffice;
              this.url = response.data.data.photo;
            } else {
              Swal.fire({
                title: 'NID number length is not correct',
                text: 'Nid number must be 10 or 13 or 17 digits',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              });
              // error data not found on server and nidserver
              this.showTrainerInfo = false;
            }
          })
          .catch(({ response }) => {
            this.load = false;
            console.log(response);
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          });
      }
    },
  },
  setup() {
    const AssessorSchema = Yup.object().shape({
      email: Yup.string().email().label('Email'),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().label('Name'),
      father_name: Yup.string().label("Father's Name"),
      mother_name: Yup.string().label("Mother's Name"),
      nid: Yup.number().min(10).label('National ID'),
      // nid:Yup.number().test('len', 'Must be exactly 5 characters', (nid) => { if(nid) return nid.toString().length === 8; }),
      gender: Yup.string().label('Gender'),
      religion: Yup.string().label('Religion'),
      mobile: Yup.number().min(11).label('Mobile'),

      // password: Yup.string()
      //   .required()
      //   .matches(
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      //   )
      //   .label("Password"),
      // confirmPassword: Yup.string()
      //   .oneOf([Yup.ref("password"), null], "Password didn't match!")
      //   .label("Confirm Password"),
    });
    return {
      AssessorSchema,
    };
  },
});
